.container {
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #000;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.wrapper-for-dot {
  margin: 10px;
  align-self: flex-start;
  color: black;
  text-align: left;
  border-radius: 0px 20px 20px 20px !important;
  background-color: rgba(255, 255, 255, 0.6) !important;
  overflow: visible !important;
  height: fit-content !important;
  width: fit-content !important; 
}