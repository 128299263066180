
.rhap_volume-indicator { 
  background:rgb(255, 251, 251);
  filter: none;
}
.rhap_progress-filled {
  background: red !important;
}
.rhap_volume-filled {
  background: red !important;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderAudioPlayback {
  width: 90%;
  margin: 10px 0;
}

.controlsAudio {
  display: flex;
  height: 50;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.time-container {
  display: flex;
  align-items: center;
  margin: 5px 0;
  width: 110px;
}

.time-text {
  color: rgba(245, 245, 245, 0.99) !important;
  font-size: 11px;
  padding: 0 10px;
}

.control-button-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.control-button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.play-pause-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}  
 
.rewind-btn,
.forward-btn {
  width: 35px;
  height: 35px;
  filter: 'invert(100%)' 
}

.rewindText {
  position: absolute; 
  width: 35px;
  margin: 0;
  color: rgba(245, 245, 245, 0.99) !important;
  font-size: 12px;
  z-index: 2;
  text-align: center;
  align-self: center;
}

.time-container-forward {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: 'red';
  width: '100%';
  height: '100%';
}

.rewind-Container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
 }

.rhap_main-controls-button{
  width: 40px;
  height: 40px;
}
.rewindImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

 

/* .rhap_volume-container, */
.rhap_repeat-button {
  display: none !important;
}

.main-container {
  background-color: rgba(0, 0, 0, 0.7); 
  background: 'black';
  border-radius: 10px;
  width: 100%;
  display: flex; 
  filter: none; 
 }

.rhap_container {
  /* background-color: rgba(0, 0, 250, 0.9); */
  width: 100%;
  display: flex;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-family: inherit;
  width: 100%;
  padding: 15px 20px;
  background-color: transparent; 
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  
}

.rhap_current-time {
  color: '#fff' !important;
}

.rhap_volume-button {
  color: rgb(255, 255, 255) !important;
}

.rhap_time {
  color: rgb(255, 255, 255) !important;
}

.rhap_progress-filled {
  background: red !important;
}
.rhap_progress-indicator {
  
  background:rgb(255, 251, 251); 
  filter: none;

}

@media (prefers-color-scheme: dark) { 
  .rhap_progress-indicator { 
    background:rgb(255, 0, 0); 
    filter: invert(0); 
  }
  .rhap_volume-indicator {  
    background:rgb(255, 0, 0); 
    filter: invert(0); 
  }
}

 