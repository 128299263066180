.wrapper-with-start {
  width: 100%;
  max-width: 390px;
  margin: 10px;
  align-self: flex-start;
  text-align: left;
  border-radius: 0px 20px 20px 20px !important;
  overflow: visible !important;
  height: fit-content !important;
}
.wrapper-with-end {
  width: 100%;
  max-width: 390px;
  margin: 10px;
  align-self: flex-end;
  text-align: left;
  border-radius: 0px 20px 20px 20px !important;
  overflow: visible !important;
  height: fit-content !important;
}

#chat::-webkit-scrollbar {
  -webkit-appearance: none;
}
