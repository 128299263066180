.webcam-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.webcam-container .webcam-relative {
  position: relative;
  border-radius: 8px;
}

.webcam-thumbnail img,
.webcam-live img {
  width: 100%;
  height: auto;  
  border-radius: 10px; 
}

.webcam-thumbnail .delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}

.camera-icon-btn {
  padding: 0 20px;
  border-radius: 5px;
  margin-top: 7px;
} 
.switch-camera-btn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.img-capture-view {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 16px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
  min-height: 30px;
}
.cap-button-row {
  display: flex;
   gap: 10px;  
  width: 100%; 
  justify-content: center;
  align-items: center;
}
.cap-row-button {
  flex: 1; /* Makes all buttons equal in size */
  padding: 5px;
  text-align: center;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flipCameraContainer {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}