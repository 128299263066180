.horizontal-slider {
  width: 100%;
  border-radius: 15px;
}
.uiSlider-thumb {
  font-size: 0.9em;
  color: black;
  cursor: pointer;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  display: flex;
  top: -15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid rgb(20, 136, 152);
  box-sizing: border-box;
}
.rc-slider .rc-slider-thumb:active {
  border: 2px solid #f00 !important; /* Ensure border change when active */
}

.horizontal-slider .uiSlider-thumb .uiSlider-thumb-1 .active {
  border-color: red !important;
}
.uiSlider-track {
  position: relative;
  background: rgb(20, 136, 152);
}

.uiSlider-track.uiSlider-track-1 {
  background: #fff;
}

.uiSlider-track-range {
  position: relative;
  background: #fff;
}

.uiSlider-track-range.uiSlider-track-range-1 {
  background: rgb(20, 136, 152);
}
.uiSlider-track-range.uiSlider-track-range-2 {
  background: #fff;
}
.uiSlider-mark {
  width: 8px;
  height: 8px;
  border: 2px solid #fff;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.uiSlider-mark.active {
  border-color: red;
}
.horizontal-slider .uiSlider-track {
  height: 3px;
  border-radius: 10px;
}

.horizontal-slider .uiSlider-track-range {
  height: 3px;
  border-radius: 10px;
}

.horizontal-slider .uiSlider-thumb {
  width: 30px;
  height: 30px;
  line-height: 15px;
  background-color: white;
  border-color: rgb(20, 136, 152);
}

.horizontal-slider .uiSlider-mark {
  margin: 0 calc(25px - 6px);
  bottom: calc(50% - 6px);
}

.uiSlider-thumb:hover {
  border-color: rgb(20, 136, 152); /* Border color on hover */
  outline: rgb(20, 136, 152);
}
.uiSlider-thumb:active {
  border-color: rgb(20, 136, 152);
  outline: rgb(20, 136, 152); /* Border color when thumb is active */
}

.uiSlider-thumb:focus {
  border-color: rgb(20, 136, 152);
  outline: rgb(20, 136, 152);
}
/* custom-slider.css */
.custom-slider .ant-slider-track {
  background-color: #148898;
  border-radius: 11px;
}
.custom-slider .ant-slider-track:hover {
  background-color: #148898 !important;
}
.custom-slider .ant-slider-rail {
  background-color: #f5f5f5 !important;
  border-radius: 11px;
}
.ant-slider:hover .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #148898 !important;
}
.ant-slider:hover .ant-slider-track {
  background-color: #148898 !important;
}

.custom-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #22b7cc;
}
.custom-slider .ant-slider-handle:hover::after {
  box-shadow: 0 0 0 4px #22b7cc !important;
}
.custom-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px #22b7cc !important;
}

/* custom switch css */
.ant-switch {
  background: rgba(0, 0, 0, 0.25);
  outline: rgba(0, 0, 0, 0.5);
  outline-style: solid;
  outline-width: 1px;
}

.ant-switch.ant-switch-checked {
  background: #148898;
  outline: #116c79;
  outline-style: solid;
  outline-width: 1px;
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #148898 !important;
}
/* input placeholder css */
input::placeholder {
  color: #9ca3af !important;
}

/* For cross-browser compatibility */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.5) !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.5) !important;
  opacity: 1; /* Firefox requires this to show the color properly */
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.5) !important;
  opacity: 1;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.5) !important;
}
/* input placeholder css */
.ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

/* radio button css */
.ant-radio-checked::after {
  border: 1px solid #086288 !important;
  border-radius: 50%;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #086288 !important;
  background-color: white !important;
  box-shadow: inset 0px 6px 8px 0px rgba(0, 0, 0, 0.15);
}
.ant-radio-inner::after {
  background-color: #086288 !important;
}

.ant-radio-button-wrapper-checked {
  color: black !important;
  background-color: #148898 !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: #148898 !important;
}
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  color: #148898;
}

/* send icon button css */
.send-icon {
  transform: rotate(45deg);
  margin-left: -2px;
}

.ant-upload-list-item {
  border: none !important;
}

.MuiCardHeader-content {
  width: -webkit-fill-available;
  max-width: 100%;
}

.ant-input-number-input {
  color: unset !important;
}
.ant-select-selector {
  background-color: unset !important;
  color: unset !important;
}

.ant-input-outlined {
  height: 36px;
}

.nt-select-selector {
  border: none;
}

.ant-select-show-search,
.ant-input-group-wrapper {
  border-radius: 7px;
}

.ant-input-wrapper > .ant-input-outlined {
  background-color: transparent !important;
}

.ant-select-selector > .ant-select-selection-placeholder {
  color: unset !important;
  opacity: 0.6;
}

.ant-select-arrow,
.eye-invisible,
.anticon-close-circle,
.ant-picker-suffix,
.anticon-close {
  color: #6e6e6e !important;
}

.ant-select-selector,
.ant-input-outlined,
.ant-input-group-addon {
  border: 0 !important;
}

.ant-radio-button-wrapper-in-form-item {
  border: 0.5;
}

.number-input-dark .ant-input-number-handler-wrap {
  background-color: gray !important;
}

.number-input-dark svg {
  color: white !important;
}

.file-upload-dark  span {
  color: white !important;
}
.ant-radio-inner::after {
  transform: scale(0.5) !important;
  inset-block-start: 49% !important;
  inset-inline-start: 49% !important;
}

.ant-select-item-option{
  padding:  4px 10px !important;
}