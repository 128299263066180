.chart-title { 
  font-size: 20px;
  color: black;
  text-align: center;
  margin-bottom: -30px;
 }
.chart-container {
background-color: white;
flex-direction: column;
margin-top: 10px;
padding-bottom: 10px;

}